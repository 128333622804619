import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';

const AsyncLegacyTokenInterceptor = React.lazy(
	() => import('../components/LegacyTokenInterceptor')
);

const LazyRecentlyDeletedPage = React.lazy(
	() => import('../components/ClientList/RecentlyDeleted')
);

export const RecentlyDeletedPage: React.FC<PageComponentProps> = ({
	piral,
	...componentProps
}) => {
	return (
		<React.Suspense fallback={<div data-testid="loading-legacy-token-interceptor" />}>
			<AsyncLegacyTokenInterceptor piral={piral} {...componentProps}>
				<LazyRecentlyDeletedPage />
			</AsyncLegacyTokenInterceptor>
		</React.Suspense>
	);
};
