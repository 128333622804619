import React from 'react';
import {
	OverlayDrawerComponentProps,
	OverlayModalComponentProps,
} from '@sharefiledev/sharefile-appshell';
import { PreConfiguredBlock } from '@sharefiledev/solution-view-engine';

interface ClientIntakeDrawerProps
	extends OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-contact:intake'> {}

type CompletePayload = Required<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-contact:intake'>
>['data'];

export const ClientContactIntakeDrawer: React.FC<ClientIntakeDrawerProps> = ({
	piral,
	completeInteraction,
	cancelInteraction,
	options,
}) => {
	React.useEffect(() => {
		const completeCallback = (payload: CompletePayload) => {
			completeInteraction(payload);
		};
		const cancelCallback = () => {
			cancelInteraction();
		};
		piral.on(
			'urn:sfdrawer:client-mgt-pilet:client-contact-create-complete',
			completeCallback
		);
		piral.on(
			'urn:sfdrawer:client-mgt-pilet:client-contact-create-cancel',
			cancelCallback
		);
		return () => {
			piral.off(
				'urn:sfdrawer:client-mgt-pilet:client-contact-create-complete',
				completeCallback
			);
			piral.off(
				'urn:sfdrawer:client-mgt-pilet:client-contact-create-cancel',
				cancelCallback
			);
		};
	}, [completeInteraction, cancelInteraction, piral]);

	const clientRID = options?.clientRID;

	if (!clientRID) {
		return <div data-testid="client-contact-intake-drawer-missing-prop"></div>;
	}

	return (
		<div data-testid="client-contact-intake-drawer" style={{ height: '100%' }}>
			<PreConfiguredBlock
				piletApi={piral}
				configuration={{
					pluginId: 'urn:client-mgt-pilet',
					blockName: 'urn:block:client-mgt-pilet:client-contact-intake',
					blockParameters: {
						clientRID,
					},
				}}
			/>
		</div>
	);
};
