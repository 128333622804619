import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { ClientContactSelectorParams } from '../components/ClientContactSelector/types';

export type ClientContactSelectorProps =
	ExtensionComponentProps<ClientContactSelectorParams>;

const LazyClientContactSelector = React.lazy(
	() => import('../components/ClientContactSelector')
);
export const ClientContactSelectorExtension: React.FC<
	ClientContactSelectorProps
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-contact-selector" />}>
			<LazyClientContactSelector {...props} />
		</React.Suspense>
	);
};
