import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyClientManagementConfigurationProvider = React.lazy(
	() => import('../components/Configuration')
);
/**
 * @todo
 * This page may not be needed. It is the intended location to provide an extension slot where
 * Any client management configuration can be performed.
 *
 * Examples could include:
 *
 * * Create Service Teams
 * * Custom attributes on clients
 * * Custom attributes on client contacts
 * * Custom attributes on team members
 * * Modify the view for all employees
 */
export const AsyncClientManagementConfiguration: React.FC<PageComponentProps> = ({
	piral,
	...componentProps
}) => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-client-management-configuration" />}
		>
			<LazyClientManagementConfigurationProvider piral={piral} {...componentProps} />
		</React.Suspense>
	);
};
