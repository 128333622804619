import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import type { ClientAssociateContactParams } from '../components/ClientAssociateContact/types';

export type AssignContactToClientProps =
	ExtensionComponentProps<ClientAssociateContactParams>;

const LazyClientAssociateContact = React.lazy(
	() => import('../components/ClientAssociateContact')
);
export const AssignContactToClientExtension: React.FC<
	AssignContactToClientProps
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-associate-contacts" />}>
			<LazyClientAssociateContact {...props} />
		</React.Suspense>
	);
};
