import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyAddServiceContactsDrawer = React.lazy(
	() => import('../components/AddServiceContactsDrawer')
);
export const AddServiceContactsDrawer: React.FC<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:service-contacts:add'>
> = ({ piral, options, cancelInteraction, completeInteraction }) => {
	if (!options || !options.clientRID) {
		return <div data-testid="add-service-contacts-drawer-error" />;
	}
	return (
		<React.Suspense fallback={<div data-testid="loading-add-service-contacts-drawer" />}>
			<LazyAddServiceContactsDrawer
				initialParameters={{
					clientRID: options?.clientRID,
					existingContacts: options?.existingContacts,
					piral,
					cancelInteraction,
					completeInteraction,
				}}
			/>
		</React.Suspense>
	);
};
