import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyClientAssociateResourcesDrawer = React.lazy(
	() => import('../components/ClientAssociateResourcesDrawer')
);
export const AssociateResourceToClientDrawer: React.FC<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-associate-resources:intake'>
> = props => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-client-associate-resources-drawer" />}
		>
			<LazyClientAssociateResourcesDrawer initialParameters={props} />
		</React.Suspense>
	);
};
