import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AddServiceContactsDrawer } from './AddServiceContactsDrawer';
import { AssociateContactToClientDrawer } from './AssociateContactToClientDrawer';
import { AssociateResourceToClientDrawer } from './AssociateResourceToClientDrawer';
import { ClientContactIntakeDrawer } from './ClientContactIntakeDrawer';
import { ClientIntakeDrawer } from './ClientIntakeDrawer';
import { ClientUpdateDrawer } from './ClientUpdateDrawer';
import { SuggestedContactsDrawer } from './SuggestedContactsDrawer';

export function addOverlays(piral: PiletApi) {
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client:intake',
		props => {
			return <ClientIntakeDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '500px',
			},
		}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-contact:intake',
		props => {
			return <ClientContactIntakeDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-update',
		props => {
			return <ClientUpdateDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:service-contacts:add',
		props => {
			return <AddServiceContactsDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-associate-resources:intake',
		props => {
			return <AssociateResourceToClientDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-associate-contacts:intake',
		props => {
			return <AssociateContactToClientDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:suggested-contacts',
		props => {
			return <SuggestedContactsDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
}
