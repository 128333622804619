import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';

const LazySuggestedContactsDrawer = React.lazy(
	() => import('../components/SuggestedContactsDrawer')
);
export const SuggestedContactsDrawer: React.FC<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:suggested-contacts'>
> = ({ piral, options, cancelInteraction, completeInteraction }) => {
	if (!options || !options.clientRID) {
		return <div data-testid="suggested-contacts-drawer-error" />;
	}
	return (
		<React.Suspense fallback={<div data-testid="loading-suggested-contacts-drawer" />}>
			<LazySuggestedContactsDrawer
				initialParameters={{
					clientRID: options?.clientRID,
					piral,
					cancelInteraction,
					completeInteraction,
					suggestedContacts: options?.suggestedContacts,
				}}
			/>
		</React.Suspense>
	);
};
