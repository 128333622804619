import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import type { ClientConfigurationDataAccessorParams } from '../components/ClientConfigurationDataAccessor/types';

export type ClientConfigurationDataAccessorProps =
	ExtensionComponentProps<ClientConfigurationDataAccessorParams>;

const LazyClientConfigurationDataAccessor = React.lazy(
	() => import('../components/ClientConfigurationDataAccessor')
);
export const ClientConfigurationDataAccessorExtension: React.FC<
	ClientConfigurationDataAccessorProps
> = props => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-client-configuration-data-accessor" />}
		>
			<LazyClientConfigurationDataAccessor {...props} />
		</React.Suspense>
	);
};
