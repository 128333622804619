import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';
import { PreConfiguredBlock } from '@sharefiledev/solution-view-engine';
import { ClientEntity } from '../generated/models';

interface ClientUpdateDrawerProps
	extends OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-update'> {}

type CompletePayload = Required<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-update'>
>['data'];

export const ClientUpdateDrawer: React.FC<ClientUpdateDrawerProps> = ({
	piral,
	completeInteraction,
	cancelInteraction,
	options,
}) => {
	React.useEffect(() => {
		const completeCallback = ({ client }: { client: ClientEntity }) => {
			completeInteraction({ client });
		};
		const cancelCallback = () => {
			cancelInteraction();
		};
		piral.on('urn:sfdrawer:client-mgt-pilet:client-update-complete', completeCallback);
		piral.on('urn:sfdrawer:client-mgt-pilet:client-update-cancel', cancelCallback);
		return () => {
			piral.off('urn:sfdrawer:client-mgt-pilet:client-update-complete', completeCallback);
			piral.off('urn:sfdrawer:client-mgt-pilet:client-update-cancel', cancelCallback);
		};
	}, [completeInteraction, cancelInteraction, piral]);

	const clientRID = options?.clientRID;

	if (!clientRID) {
		return <div data-testid="client-update-drawer-missing-prop"></div>;
	}

	return (
		<div data-testid="client-update-drawer" style={{ margin: '-20px -24px -32px' }}>
			<PreConfiguredBlock
				piletApi={piral}
				configuration={{
					pluginId: 'urn:client-mgt-pilet',
					blockName: 'urn:block:client-mgt-pilet:client-update',
					blockParameters: {
						contentPadding: '20px',
						clientRID,
					},
				}}
			/>
		</div>
	);
};
