import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { NavigateFromFolderToClientParams } from '../components/NavigateFromFolderToClient/types';

export type LinkFolderToClientProps =
	ExtensionComponentProps<NavigateFromFolderToClientParams>;

const LazyNavigateFromFolderToClientExtension = React.lazy(
	() => import('../components/NavigateFromFolderToClient')
);
export const NavigateFromFolderToClientExtension: React.FC<
	LinkFolderToClientProps
> = props => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-navigate-from-folder-to-client" />}
		>
			<LazyNavigateFromFolderToClientExtension {...props} />
		</React.Suspense>
	);
};
