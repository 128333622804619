import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyBlockDevelopment = React.lazy(() => import('./BlockDevelopment'));
export const AsyncBlockDevelopment: React.FC<PageComponentProps> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-block-development" />}>
			<LazyBlockDevelopment {...props} />
		</React.Suspense>
	);
};
