import { Cog } from '@sharefiledev/icons';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncBlockDevelopment } from './BlockDevelopment';
import { AsyncExtensionDevelopment } from './ExtensionDevelopment';

export function addBlockDevelopment(piral: PiletApi) {
	piral.sf.registerLeftNavComponent({
		href: '/client-mgt-pilet-block-development',
		title: () => 'Client Mgt Pilet Block Dev',
		icon: Cog,
		weight: 50,
	});
	piral.registerPage('/client-mgt-pilet-block-development', AsyncBlockDevelopment);

	piral.sf.registerLeftNavComponent({
		href: '/client-mgt-pilet-ext-development',
		title: () => 'Client Mgt Ext Dev',
		icon: Cog,
		weight: 51,
	});
	piral.registerPage('/client-mgt-pilet-ext-development', AsyncExtensionDevelopment);
}
