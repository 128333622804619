import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import type { PluginProvidedEntitySelectorExtensionSlotParams } from '@sharefiledev/workflows-pilet';

export type ClientMgtContactEntityType = {
	/** Client Contact RID */
	Id: string;
	UserId: string;
	UserRID: string;
	Email: string;
	FirstName: string;
	LastName: string;
	Company: string;
	/** Is a primary point of contact */
	PrimaryContact: boolean;
	ClientRID: string;
	ClientInternalName: string;
	ClientIcon: string;
};

export type WorkflowsClientContactSelectorParams =
	PluginProvidedEntitySelectorExtensionSlotParams<ClientMgtContactEntityType>;

export type WorkflowsClientContactSelectorProps =
	ExtensionComponentProps<WorkflowsClientContactSelectorParams>;

const LazyWorkflowClientContactSelector = React.lazy(
	() => import('../components/WorkflowClientContactSelector')
);
export const WorkflowsClientContactSelectorExtension: React.FC<
	WorkflowsClientContactSelectorProps
> = props => {
	return (
		<React.Suspense
			fallback={<div data-testid="loading-workflow-clinet-contact-selector" />}
		>
			<LazyWorkflowClientContactSelector {...props} />
		</React.Suspense>
	);
};
