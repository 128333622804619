const clientManagementPrefix = 'clientManagement_';

export const events = {
	dismissSuggestedContact: `${clientManagementPrefix}dismissSuggestedContact`,
	addedSuggestedContact: `${clientManagementPrefix}addedSuggestedContact`,
	dismissSuggestedClient: `${clientManagementPrefix}dismissSuggestedClient`,
	addedSuggestedClient: `${clientManagementPrefix}addedSuggestedClient`,
	legacyTokenDetected: `${clientManagementPrefix}LegacyTokenDetected`,
	redirectToManageUser: `${clientManagementPrefix}RedirectToManageUser`,
	clientListFilterSelected: `${clientManagementPrefix}ClientListFilterSelected`,
	createClient: `${clientManagementPrefix}createClient`,
	addServiceContact: `${clientManagementPrefix}addServiceContact`,
	addClientContact: `${clientManagementPrefix}addClientContact`,
	makePrimaryClientContact: `${clientManagementPrefix}makePrimaryClientContact`,
	makePrimaryServiceContact: `${clientManagementPrefix}makePrimaryServiceContact`,
};

export const analyticsIds = {
	addSingleSuggestedContact: `${clientManagementPrefix}AddSingleSuggestedContact`,
	setClientContactAsPrimary: `${clientManagementPrefix}SetClientContactAsPrimary`,
	deleteClientsFromContextBar: `${clientManagementPrefix}DeleteClientsFromContextBar`,
	deleteSingleClientFromActions: `${clientManagementPrefix}DeleteSingleClientFromActions`,
	removeClientContactPrimaryDesignation: `${clientManagementPrefix}RemoveClientContactPrimaryDesignation`,
	removeClientContact: `${clientManagementPrefix}RemoveClientContact`,
	removeTeamMember: `${clientManagementPrefix}RemoveTeamMember`,
	setTeamMemberAsPrimary: `${clientManagementPrefix}SetTeamMemberAsPrimary`,
	removeTeamMemberPrimaryDesignation: `${clientManagementPrefix}RemoveTeamMemberPrimaryDesignation`,
	legacyTokenReauth: `${clientManagementPrefix}LegacyTokenReauth`,
	showMyClientsCheckbox: `${clientManagementPrefix}ShowMyClientsOnlyCheckbox`,
	restoreClient: `${clientManagementPrefix}RestoreClient`,
	navigateFromFolderToClient: `${clientManagementPrefix}NavigateFromFolderToClient`,
};

interface Window {
	pendo?: any;
}

export function sendPendoTrackEvent(event: string, metadata: any = {}) {
	try {
		const pendo = (window as unknown as Window).pendo;
		if (!pendo?.track) {
			return;
		}
		const metadataWithProductName = {
			...metadata,
			product: 'ShareFile - Client Management',
			type: event,
		};
		pendo.track(event, metadataWithProductName);
	} catch {}
}
