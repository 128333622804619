import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import type { ClientAssociateResourcesParams } from '../components/ClientAssociateResources/types';

export type AssignResourceToClientProps =
	ExtensionComponentProps<ClientAssociateResourcesParams>;

const LazyClientAssociateResources = React.lazy(
	() => import('../components/ClientAssociateResources')
);
export const AssignResourceToClientExtension: React.FC<
	AssignResourceToClientProps
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-associate-resources" />}>
			<LazyClientAssociateResources {...props} />
		</React.Suspense>
	);
};
