import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';
import { PreConfiguredBlock } from '@sharefiledev/solution-view-engine';
import { ClientEntity } from '../generated';
import { events, sendPendoTrackEvent } from '../Tracking/pendo';

interface ClientIntakeDrawerProps
	extends OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client:intake'> {}

export const ClientIntakeDrawer: React.FC<ClientIntakeDrawerProps> = ({
	piral,
	completeInteraction,
	cancelInteraction,
	options,
}) => {
	React.useEffect(() => {
		const completeCallback = ({ client }: { client: ClientEntity }) => {
			completeInteraction({ client });
			sendPendoTrackEvent(events.createClient);
		};
		const cancelCallback = () => {
			cancelInteraction();
		};
		piral.on('urn:sfdrawer:client-mgt-pilet:client-create-complete', completeCallback);
		piral.on('urn:sfdrawer:client-mgt-pilet:client-create-cancel', cancelCallback);
		return () => {
			piral.off('urn:sfdrawer:client-mgt-pilet:client-create-complete', completeCallback);
			piral.off('urn:sfdrawer:client-mgt-pilet:client-create-cancel', cancelCallback);
		};
	}, [completeInteraction, cancelInteraction, piral]);

	return (
		<div data-testid="client-intake-drawer" style={{ margin: '-20px -24px -32px' }}>
			<PreConfiguredBlock
				piletApi={piral}
				configuration={{
					pluginId: 'urn:client-mgt-pilet',
					blockName: 'urn:block:client-mgt-pilet:client-intake',
					blockParameters: {
						contentPadding: '24px',
						initialActorRID: options?.initialActorRID,
						disableRedirect: options?.disableRedirect,
					},
				}}
			/>
		</div>
	);
};
