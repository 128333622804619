import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyExtensionDevelopment = React.lazy(() => import('./ExtensionDevelopment'));
export const AsyncExtensionDevelopment: React.FC<PageComponentProps> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-testing" />}>
			<LazyExtensionDevelopment {...props} />
		</React.Suspense>
	);
};
